.NativeAdsMobile-module__sBpFoG__native-ads-mobile-branding {
  display: none;
}

@media only screen and (max-width: 660px) {
  .NativeAdsMobile-module__sBpFoG__native-ads-mobile-branding {
    justify-content: center;
    width: 100%;
    display: flex;
  }
}

.Tooltip-module__lt-CPW__tooltip-container {
  z-index: 9999;
  justify-content: center;
  align-items: center;
  width: 80px;
  margin: 5px;
  display: flex;
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.Tooltip-module__lt-CPW__tooltip-container--hide-desktop {
  display: none;
}

.Tooltip-module__lt-CPW__tooltip-container--hide-mobile {
  display: flex;
}

@media only screen and (max-width: 660px) {
  .Tooltip-module__lt-CPW__tooltip-container--hide-desktop {
    display: flex;
  }

  .Tooltip-module__lt-CPW__tooltip-container--hide-mobile {
    display: none;
  }
}

